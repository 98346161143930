<template>
  <div class="ui-user" v-show="!showModal">
    <a-spin :spinning="isSpin">
      <a-form
        ref="formRef"
        :model="formState"
        name="formRef"
        @finish="onSearch"
      >
        <a-row>
          <a-form-item class="a-form-item" label="账号" name="username">
            <a-input placeholder="请输入账号" v-model:value="formState.username" />
          </a-form-item>
		  
          <a-form-item class="a-form-item" label="用户名" name="nickname">
            <a-input placeholder="请输入用户名" v-model:value="formState.nickname" />
          </a-form-item>
		  
		  <a-form-item class="a-form-item" label="手机号" name="phone">
		    <a-input placeholder="请输入手机号" v-model:value="formState.phone" />
		  </a-form-item>
		  
		 <!-- <a-form-item class="a-form-item" label="关联角色" name="phone">
		    
		  </a-form-item> -->
        </a-row>
        <a-row type="flex">
          <a-col :span="10">
            <!-- <a-button class="a-primaryBtn" type="primary" @click="toEdit">新建</a-button> -->
            <a-button v-permission="['system_manage_user_add']" class="a-primaryBtn" type="primary" @click="toAdd()"
              >新建</a-button
            >
          </a-col>
          <a-col :span="14" class="ui-search">
            <a-button type="primary" html-type="submit"
              >搜索</a-button
            >
            <a-button style="margin-left: 10px" @click="resetBtn"
              >重置</a-button
            >
          </a-col>
        </a-row>
      </a-form>

      <a-table
        :dataSource="dataList"
        :pagination="pagination"
        :columns="columns"
        size="middle"
		rowKey="id"
      >
        <template #bodyCell="{ column, record }">
			<template v-if="column.key === 'roles'">
				<a-tag v-for="item in record.adminRoleList" :key="item.id">{{ item.title }}</a-tag>
			</template>
          <template v-if="column.dataIndex === 'createTime'">
            {{ transDateTime(record.createTime) }}
          </template>
            <template v-if="column.key === 'lastTime'">
                {{ transDateTime(record.lastTime)  }}
            </template>
          <template v-if="column.key === 'isDisabled'">
            {{ judgeStatus(record.isDisabled) }}
          </template>
          <template v-if="column.dataIndex === 'operate'">
            <a-dropdown>
              <a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon></a-button>
              <template #overlay>
                <a-menu>
					<div @click="toSee(record)">
						<a-menu-item>
						  <a>查看</a>
						</a-menu-item>
					</div>
					<div v-permission="['system_manage_user_edit']" @click="toEdit(record)">
						<a-menu-item>
						  <a>编辑</a>
						</a-menu-item>
					</div>
					<div v-permission="['system_manage_user_updatePassword']" v-if="record.id !== $store.state.system.userInfo.id" @click="onEditPassword(record)">
						<a-menu-item>修改密码</a-menu-item>
					</div>
					<div v-permission="['system_manage_user_open']" v-if="record.isDisabled === 1" @click="toDisabled(record)">
						<a-menu-item>
						  <a>启用</a>
						</a-menu-item>
					</div>
					<div v-permission="['system_manage_user_disabled']" v-else @click="toDisabled(record)">
						<a-menu-item>
						  <a>禁用</a>
						</a-menu-item>
					</div>
					<div v-permission="['system_manage_user_delete']" @click="toDelete(record)">
						<a-menu-item>
						  <a>删除</a>
						</a-menu-item>
					</div>
                </a-menu>
              </template>
            </a-dropdown>
          </template>
        </template>
      </a-table>
	  <a-modal v-model:visible="visible" title="修改密码" width="650px" @ok="onQuery">
		  <a-spin :spinning="isSpin">
			  <a-form ref="formRef" :model="modelRef" :labelCol="{ span: 4 }" :wrapperCol="{span: 18}">
				  <!-- <a-form-item label="旧密码" name="oldPassword" :rules="[{ required: true, message: '必填项不允许为空' }]">
					<a-input-password v-model:value="modelRef.oldPassword" placeholder="请输入旧密码"></a-input-password>
				  </a-form-item> -->
				  
				  <a-form-item label="新密码" name="newPassword" :rules="[{ required: true, message: '必填项不允许为空' }]">
					<a-input-password v-model:value="modelRef.newPassword" placeholder="请输入新密码"></a-input-password>
				  </a-form-item>
				  
				  <a-form-item label="确认新密码" name="confirmPassword" :rules="[{ required: true, message: '必填项不允许为空' }]">
				  	<a-input-password v-model:value="modelRef.confirmPassword" placeholder="请输入新密码"></a-input-password>
				  </a-form-item>
			  </a-form>
		  </a-spin>
	  </a-modal>
    </a-spin>
  </div>
  <temp v-if="showModal" :isEdit="isEdit" :isSee="isSee" :id="id" @back="onBack"></temp>
</template>

<script>
import * as systemApi from "@/service/modules/system";
import { Icon } from "@/components/icon/icon.js";
import temp from './temp.vue';
export default {
  components: { Icon, temp },
  data() {
    return {
      isSpin: false,
	  visible: false,
      formState: {}, //搜索数据
	  searchData: {},
      dataList: [],
      columns: [
        {
          title: "账号",
          dataIndex: "username",
        },
        {
          title: "用户名",
          dataIndex: "nickname",
        },
        {
          title: "手机号",
          dataIndex: "phone",
        },
		{
		  title: "关联角色",
		  key: 'roles'
		},
		{
			title: '是否启用',
			key: 'isDisabled'
		},
        {
          title: "创建时间",
          dataIndex: "createTime",
        },{
          title: '创建者',
          dataIndex: 'createUserName'
        },{
          title: '最后登录时间',
          key: 'lastTime'
        },
        {
          title: "操作",
          dataIndex: "operate",
        },
      ],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        size: "middle",
        showTotal: (total) => {
          return "共 " + total + " 条";
        },
        total: 0,
        current: 1,
        pageSize: 20,
        onChange: (page) => {
          this.pagination.current = page;
          this.getData();
        },
        onShowSizeChange: (page, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
          this.getData();
        }
      },
	  showModal: false,
	  isEdit: false,
	  isSee: false,
	  id: 0,
	  modelRef: {}
    };
  },
  created() {
    this.getData();
  },
  methods: {
	  onBack(isRef) {
		  this.showModal = false;
		  if(isRef) {
			  this.getData();
		  }
	  },
    onSearch() {
     this.pagination.current = 1;
     this.searchData = JSON.parse(JSON.stringify(this.formState));
     this.getData();
    },
    getData() {
      const self = this;
      self.isSpin = true;
      let postData = {
        page: self.pagination.current,
        pageSize: self.pagination.pageSize,
		...this.searchData
      };
      systemApi.userList(postData).then((res) => {
        self.isSpin = false;
        if (res.code == 200) {
          this.dataList = res.data.list;
          this.pagination.total = res.data.totalCount;
        }
      });
    },
    toEdit(item) {
		this.isEdit = true;
		this.id = item.id;
		this.isSee = false;
		this.showModal = true;
      //console.log("item", item);
      // this.$router.push({
      //   name: "userEdit",
      //   // path: "@/views/system/user/userEdit",
      //   query: {
      //     id: item.id,
      //   },
      // });
    },
	onEditPassword(item){
		this.modelRef = {};
		this.id = item.id;
		this.visible = true;
	},
	onQuery() {
		this.$refs.formRef.validate().then(() => {
			this.$confirm({
				title: '提示',
				content: '确认修改密码吗？',
				onOk: async ()=> {
					this.isSpin = true;
					let ret = await systemApi.updPassword({
						userId: this.id,
						...this.modelRef
					})
					this.isSpin = false;
					if (ret.code === 200) {
						this.visible = false;
						this.isSpin = false;
						this.$message.success('修改成功！');
					}
				}
			})
		})
	},
	toSee(item) {
		this.isEdit = true;
		this.isSee = true;
		this.id = item.id;
		this.showModal = true;
	},
    toAdd(item) {
		this.isEdit = false;
		this.id = 0;
		this.isSee = false;
		this.showModal = true;
      // this.$router.push({ path: "/system/systemRoot/user/userAdd" });
    },
    resetBtn() {
      this.$refs.formRef.resetFields();
      this.onSearch();
    },
    judgeStatus(item){
        if(item == 1){
            return '禁用';
        }else{
            return '正常';
        }
    },
    toDelete(item){
        this.$confirm({
			title: '提示',
            content: '确定要删除吗?',
            onOk: ()=>{
                systemApi.userDel({id:item.id}).then((res)=>{
                    //console.log(res);
                    if(res.code == 200){
                        this.$message.success("删除成功");
                        this.getData();
                    }
                })
            }
        })
    },
    toDisabled(item){
        this.$confirm({
            title: '提示',
			content: '确定要'+ (item.isDisabled ? '启用' : '禁用') +'此用户?',
            onOk:()=>{
                systemApi.userChangeStatus({
					id: item.id,
					status: item.isDisabled ? 0 : 1
				}).then((res)=>{
                    if(res.code == 200){
                        this.$message.success((item.isDisabled ? '启用' : '禁用') + '成功');
                        this.getData();
                    }
                })
            }
        })
    }
  }
}
</script>

<style lang="less" scoped>
.ui-user {
  // background: red;
  text-align: left;
}
.a-form-item {
  margin-right: 30px;
}
.a-primaryBtn {
  margin-bottom: 20px;
}
.ui-search {
  text-align: right;
}
</style>
