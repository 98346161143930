<template>
    <div>
        <a-spin :spinning="loading">
			<Header :title="isEdit ? '修改用户' : '添加用户'" @back="onBack(false)"></Header>
            <a-form ref="formRef" :model="modelRef" @finish="onSubmit" style="margin-top: 20px;" :labelCol="{span: 4, xxl: 3}"
			:wrapperCol="{span: 16, xxl: 15 }">
				
				<a-form-item name="username" label="用户账户" :rules="[{required: true, message: '必填项不允许为空'}]">
				    <a-input :disabled="isEdit" v-model:value="modelRef.username"/>
				</a-form-item>
				
				<a-form-item
					v-if="!isEdit"
				  label="密码"
				  name="password"
				  :rules="[{ required: true, message: '请输入密码' }]"
				>
				  <a-input-password :disabled="isSee" v-model:value="modelRef.password"></a-input-password>
				</a-form-item>
				
				<!-- <a-form-item
					v-if="isEdit"
				  label="密码"
				  name="newPassword"
				>
				  <a-input-password :disabled="isSee" v-model:value="modelRef.newPassword"></a-input-password>
				</a-form-item> -->
				
				<a-form-item label="用户名" name="nickname" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-input :disabled="isSee" v-model:value="modelRef.nickname" placeholder="请输入用户名"/>
				</a-form-item>
				
				<a-form-item label="手机号" name="phone" >
					<a-input :disabled="isSee" v-model:value="modelRef.phone" placeholder="请输入手机号"/>
				</a-form-item>
				
				<a-form-item label="是否启用" name="isDisabled">
					<a-switch :disabled="isSee" v-model:checked="modelRef.isDisabled" checked-children="启用" un-checked-children="禁用"></a-switch>
				</a-form-item>
				
				<a-form-item label="关联角色" name="roleIds" :rules="[{required: true, message: '必选项不允许为空'}]">
					<a-select :disabled="isSee" v-model:value="modelRef.roleIds" mode="multiple" placeholder="请选择关联角色">
						<a-select-option v-for="item in roleList" :key="item.id" :value="item.id">{{ item.title }}</a-select-option>
					</a-select>
				</a-form-item>
				
				<a-form-item label="影院组织" name="organizationId" :rules="[{required: true, message: '必选项不允许为空'}]">
					<a-select :disabled="isSee" v-model:value="modelRef.organizationId" placeholder="请选择影院组织" @change="getCinemaList">
						<a-select-option v-for="item in organizationList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
					</a-select>
				</a-form-item>
				
				<a-form-item label="关联影院" name="cinemaIds" :rules="[{required: true, message: '必选项不允许为空'}]">
					<a-select :disabled="isSee" v-model:value="modelRef.cinemaIds" placeholder="请选择关联影院" mode="multiple">
						<a-select-option v-for="item in cinemaList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
					</a-select>
				</a-form-item>
				
                <div style="margin-top: 20px;text-align: center;">
                    <a-col :span="16">
						<a-button v-if="!isSee" type="primary" html-type="submit" :loading="loading" style="margin-right: 20px;">提交</a-button>
						<a-button @click="onBack(false)">返回</a-button>
					</a-col>
                </div>
            </a-form>
        </a-spin>
    </div>
</template>

<script>
	import { Icon } from '@/components/icon/icon.js';
	import Header from '@/components/header/header.vue';
	import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';
    import * as systemApi from '@/service/modules/system';
    export default {
		components: {Header, Icon},
        props: {
            isEdit: {      // true :编辑  false: 新增
                type: Boolean,
                default: false
            },
			id: {
				type: null,
				default: 0
			},
			isSee: {
				type: Boolean,
				default: false
			},
        },
        data() {
            return {
                loading: false,
                formItemLayout: {
                    labelCol: { span: 6 },
                    wrapperCol: { span: 14 },
                },
                modelRef: {
                    title: '',
					roleIds: [],
					cinemaIds: [],
                    isDefault: false
                },
				organizationList: [],
				cinemaList: [],
				roleList: []
            }
        },
        created() {
			this.getOrganizationList();
			this.getRoleList();
            if (this.isEdit && this.id) {
                this.getData();
            }
        },
        methods: {
			onBack(isRef) {
				this.$emit('back', isRef);
			},
            onSubmit() {
				let postData = JSON.parse(JSON.stringify(this.modelRef));
				postData.isDisabled = postData.isDisabled ? 0 : 1;
				postData.roleIds = postData.roleIds.join(',');
				postData.cinemaIds = postData.cinemaIds.join(',');
                this.loading = true;
                if (this.isEdit) {
					if (!postData.newPassword) {
						delete postData.newPassword;
					}
                    postData.id = this.id;
                    systemApi.userUpdate(postData).then(res => {
                        this.loading = false;
                        if (res.code == 200) {
                            this.$message.success('操作成功');
							this.onBack(true);
                            // this.$closePage(this.$router.currentRoute.value, '/system/systemRoot/role/view');
                        }
                    })
                } else {
                    systemApi.userAdd(postData).then(res => {
                        this.loading = false;
                        if (res.code == 200) {
                            this.$message.success('操作成功');
							this.onBack(true);
                        }
                    })
                }
            },
            getData() {
                this.loading = true;
                systemApi.userDetail({id: this.id}).then(res => {
                    this.loading = false;
                    if (res.code == 200) {
						if (res.data.organizationId) {
							this.getCinemaList(res.data.organizationId);
						}
                        res.data.roleIds = res.data.adminRoleList.map(item => {
							return item.id;
						});
                        res.data.cinemaIds = res.data.cinemaIds ? res.data.cinemaIds.split(',').map(item => Number(item)) : [];
                        res.data.isDisabled = res.data.isDisabled ? false : true;
						delete res.data.password;
						this.modelRef = res.data;
                    }
                })
            },
			async getOrganizationList() {
				let ret = await getOrganizationList({
					page: 1,
					pageSize: 999999
				});
				if (ret.code === 200) {
					this.organizationList = ret.data.list;
				}
			},
			async getRoleList() {
				let ret = await systemApi.roleList({
					page: 1,
					pageSize: 999999
				});
				if (ret.code === 200) {
					this.roleList = ret.data.list;
				}
			},
			async getCinemaList(organizationId) {
				this.modelRef.cinemaCodes = [];
				this.cinemaList = [];
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId
				});
				if (ret.code === 200) {
					this.cinemaList = ret.data.list;
				}
			}
        }
    }
</script>

<style scoped>
    .ui-roleEdit {
        width: 700px;
        text-align: left;
    }
</style>